





















































































import { Component, Vue } from 'vue-property-decorator';

import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IModel } from '@/services/common/form-validate.service';
import { PanelHeaderStatusTypes } from '@/services/common/ui.service';

import {
	UserLogTypes,
	IUserLog,
	IListUserLogPayload,
	IListUserLogDto
} from '@/services/api/log-api.service';
import { UserRoles } from '@/services/payloads/user.payload';

interface ISubscriptions {
	listUserLog: Subscription | null;
	requestResult: Subscription | null;
}

@Component({
	name : 'wb-admin-user-log',
	components : {
	},
})
export default class WbAdminUserLog extends Vue {
	public panelHeaderStatusTypes = PanelHeaderStatusTypes;
	public userLogs: IUserLog[] = [];

	private subscriptions: ISubscriptions = {
		listUserLog : null,
		requestResult : null,
	}

	public filterModel: IModel = {
		fields : {
			search : {
				placeholder : 'Selection',
				oldValue : '-',
				value : '-',
				required : false,
				options : [
					{ value : '-', label : '- Choose filter -', },
					{ value : 'activeTimeSpent', label : 'Active Time Spent', },
					{ value : 'balanceChanged', label : 'Balance Changed', },
					{ value : 'balanceRequestGranted', label : 'Balance Request Granted', },
					{ value : 'balanceRequestRejected', label : 'Balance Request Rejected', },
					{ value : 'balanceTopUpRequested', label : 'Balance Top Up Requested', },
					{ value : 'configSaved', label : 'Configuration Saved', },
					{ value : 'configLoaded', label : 'Configuration Loaded', },
					{ value : 'configUploaded', label : 'Configuration Uploaded', },
					{ value : 'configDownloaded', label : 'Configuration Downloaded', },
					{ value : 'configUploadedToDevice', label : 'Configuration Uploaded To Device', },
					{ value : 'deviceConnected', label : 'Device Connected', },
					{ value : 'deviceRegistered', label : 'Device Registered', },
					{ value : 'firmwareBurnt', label : 'Firmware Burnt', },
					{ value : 'flasherDownloaded', label : 'Flasher Downloaded', },
					{ value : 'loggedIn', label : 'Logged In', },
					{ value : 'loggedOut', label : 'Logged Out', },
					{ value : 'userModified', label : 'User Modified', },
					{ value : 'userPasswordChanged', label : 'User Password Changed', }
				],
				focus : false,
				status : {},
				error : {},
			},
		},
		properties : {
			dirtyFields : [],
		},
	};

	public paginationOptions = {
		min : 1,
		current : 1,
		max : 1,
		pageSize : 25,
	};
	/**
	 * CREATED
	 */

	public created(): void {
		this.subscribeToStore();
		this.listUserLog();

		// eslint-disable-next-line no-console
		console.info('%c CREATE WbAdminUserLog ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		this.unsubscribeFromStore();

		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbAdminUserLog ', 'background: purple; color: #FFF');
	}

	private subscribeToStore(): void {
		this.subscriptions.listUserLog = this.$services.logs.userLogs$
			.pipe(
				tap((userLogPayload: IListUserLogPayload | null) => {
					if (userLogPayload) {
						this.userLogs = userLogPayload.userLogs;
						this.paginationOptions.max = Math.ceil(userLogPayload.count / this.paginationOptions.pageSize);
					}
				})
			).subscribe();
	}

	private unsubscribeFromStore(): void {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}

	private listUserLog(): void {
		const dto: IListUserLogDto = {
			page : this.paginationOptions.current,
			pageLength : this.paginationOptions.pageSize,
		};
		if (this.filterModel.fields.search.value !== '-' && dto) {
			dto.type = this.filterModel.fields.search.value as UserLogTypes;
		}
		this.$services.logs.listUserLogs(dto);
	}

	public onPaginationPageChange(newPageNumber: number): void {
		this.paginationOptions.current = newPageNumber;
		this.listUserLog();
	}

	public filterList(filter: string): void {
		this.$formValidation.onInput(this.filterModel, 'search', filter);
		this.listUserLog();
	}

	public exchangeRoleNumber(role: UserRoles): 'USER' | 'ADMIN' | 'SUPER ADMIN' | undefined {
		switch (role) {
			case UserRoles.USER :
				return 'USER';
			case UserRoles.ADMIN :
				return 'ADMIN';
			case UserRoles.SUPERADMIN :
				return 'SUPER ADMIN';
		}
	}
}
