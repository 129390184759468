



























import { Component, Vue } from 'vue-property-decorator';

import { Subscription } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';

import { PanelHeaderStatusTypes } from '@/services/common/ui.service';
import { IRequestPayload } from '@/services/dtos/shared.dto';

interface ISubscriptions {
	requestResult: Subscription | null;
}

@Component({
	name : 'wb-admin-log',
	components : {},
})
export default class WbAdminLog extends Vue {
	public panelHeaderStatusTypes = PanelHeaderStatusTypes;
	public toggle = false;

	private subscriptions: ISubscriptions = {
		requestResult : null,
	}

	/**
	 * CREATED
	 */
	public created(): void {
		this.subscribeToStore();

		// eslint-disable-next-line no-console
		console.info('%c CREATE WbAdminLog ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		this.unsubscribeFromStore();

		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbAdminLog ', 'background: purple; color: #FFF');
	}

	private subscribeToStore(): void {
		this.subscriptions.requestResult = this.$services.logs.requestResult$
			.pipe(
				skipWhile((requestPayload) => requestPayload === null),
				tap((requestPayload: IRequestPayload | null) => {
					if (requestPayload) {
						if (requestPayload.actor === 'xxxx') {
						}
					}
				})
			).subscribe();
	}

	private unsubscribeFromStore(): void {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}

	public goTo(state: string): void {
		this.$router.push({ name : 'adminBalance', });
		this.$router.push({ name : state, })
			.catch((err) => {
				// eslint-disable-next-line no-console
				console.info('ROUTER: ', state, err);
			});
	}
}
