
























































import { Component, Vue } from 'vue-property-decorator';

import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IModel } from '@/services/common/form-validate.service';

import { PanelHeaderStatusTypes } from '@/services/common/ui.service';
import {
	SystemLogTypes,
	IListSystemLogDto,
	IListSystemLogPayload,
	ISystemLog
} from '@/services/api/log-api.service';

interface ISubscriptions {
	listSystemLog: Subscription | null;
	requestResult: Subscription | null;
}

@Component({
	name : 'wb-admin-system-log',
	components : {
	},
})

export default class WbAdminSystemLog extends Vue {
	public panelHeaderStatusTypes = PanelHeaderStatusTypes;
	public systemLogs: ISystemLog[] = [];

	private subscriptions: ISubscriptions = {
		listSystemLog : null,
		requestResult : null,
	}

	public filterModel: IModel = {
		fields : {
			search : {
				placeholder : 'Selection',
				oldValue : '-',
				value : '-',
				required : false,
				options : [
					{ value : '-', label : '- Choose filter -', },
					{ value : 'info', label : 'Information', },
					{ value : 'error', label : 'Error', },
					{ value : 'cron', label : 'Cron', }
				],
				focus : false,
				status : {},
				error : {},
			},
		},
		properties : {
			dirtyFields : [],
		},
	};

	public paginationOptions = {
		min : 1,
		current : 1,
		max : 1,
		pageSize : 25,
	};
	/**
	 * CREATED
	 */

	public created(): void {
		this.subscribeToStore();
		this.listSystemLog();

		// eslint-disable-next-line no-console
		console.info('%c CREATE WbAdminSystemLog ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		this.unsubscribeFromStore();

		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbAdminSystemLog ', 'background: purple; color: #FFF');
	}

	private subscribeToStore(): void {
		this.subscriptions.listSystemLog = this.$services.logs.systemLogs$
			.pipe(
				tap((systemLogPayload: IListSystemLogPayload | null) => {
					if (systemLogPayload) {
						this.systemLogs = systemLogPayload.systemLogs;
						this.paginationOptions.max = Math.ceil(systemLogPayload.count / this.paginationOptions.pageSize);
					}
				})
			).subscribe();
	}

	private unsubscribeFromStore(): void {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}

	private listSystemLog(): void {
		const dto: IListSystemLogDto = {
			page : this.paginationOptions.current,
			pageLength : this.paginationOptions.pageSize,
		};
		if (this.filterModel.fields.search.value !== '-' && dto) {
			dto.type = this.filterModel.fields.search.value as SystemLogTypes;
		}
		this.$services.logs.listSystemLogs(dto);
	}

	public onPaginationPageChange(newPageNumber: number): void {
		this.paginationOptions.current = newPageNumber;
		this.listSystemLog();
	}

	public filterList(filter: string): void {
		this.$formValidation.onInput(this.filterModel, 'search', filter);
		this.listSystemLog();
	}
}
